import { createRouter, createWebHistory } from "vue-router";
import { getRedirectPath } from "@/redirections/redirection";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () => import(/* webpackChunkName: "404-info" */ "@/views/PageNotFoundView.vue"),
},
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/HomeView.vue"),
  },
  {
    path: "/all-programs/",
    name: "AllProgram",
    component: () =>
      import(
        /* webpackChunkName: "all-program" */ "@/views/AllProgramView.vue"
      ),
  },

  {
    path: "/:short_slug/:elective_slug",
    name: "elective-detail",
    component: () =>
      import(
        /* webpackChunkName: "elective-detail" */ "@/views/ElectiveDetailView.vue"
      ),
    props: true,
  },
  {
    path: "/blogs/",
    redirect: { name: "BlogsView" },
  },
  {
    path: "/blogs",
    name: "BlogsView",
    component: () =>
      import(/* webpackChunkName: "blog" */ "@/views/BlogListView.vue"),
  },
  {
    path: "/blogs/:slug/",
    name: "BlogView",
    component: () =>
      import(/* webpackChunkName: "blog-detail" */ "@/views/BlogView.vue"),
    props: true,
  },
  {
    path: "/news-media/",
    name: "NewsMedia",
    component: () =>
      import(/* webpackChunkName: "news-media" */ "@/views/NewsMedia.vue"),
  },
  {
    path: "/refund-policy/",
    name: "Refund",
    component: () =>
      import(/* webpackChunkName: "refund" */ "@/views/RefundView.vue"),
  },
  {
    path: "/testimonial/",
    name: "Success",
    component: () =>
      import(/* webpackChunkName: "AllProgram" */ "@/views/SuccessStories.vue"),
  },
  {
    path: "/academics/",
    name: "AcademicView",
    component: () =>
      import(/* webpackChunkName: "academic" */ "@/views/AcademicView.vue"),
  },
  {
    path: "/grievance-redressal/",
    name: "GrievanceRedressal",
    component: () =>
      import(
        /* webpackChunkName: "grievance-redressal" */ "@/views/GrievanceRedressal.vue"
      ),
  },
  {
    path: "/scholarships/",
    name: "ScholarshipView",
    component: () =>
      import(
        /* webpackChunkName: "scholarships" */ "@/views/ScholarshipView.vue"
      ),
  },
  {
    path: "/privacy-policy/",
    name: "PrivacyView",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy/" */ "@/views/PrivacyView.vue"
      ),
  },
  {
    path: "/mandatory-disclosures/",
    name: "DisclosuresView",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy/" */ "@/views/DisclosuresView.vue"
      ),
  },
  {
    path: "/notifications/",
    name: "NotificationView",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "@/views/NotificationView.vue"
      ),
  },

  {
    path: "/faqs/",
    name: "FaqsView",
    component: () =>
      import(/* webpackChunkName: "faqs" */ "@/views/FaqView.vue"),
  },
  {
    path: "/faqs/:slug/",
    name: "FaqInner",
    component: () =>
      import(/* webpackChunkName: "faq-detail" */ "@/views/FaqInner.vue"),
    props: true,
  },
  {
    path: "/contact-us/",
    name: "ContactView",
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/ContactView.vue"),
  },

  {
    path: "/enquire/",
    name: "EnqurieView",
    component: () =>
      import(/* webpackChunkName: "enquire" */ "@/views/EnqurieView.vue"),
  },
  {
    path: "/:slug/",
    name: "ProgramView",
    component: () =>
      import(/* webpackChunkName: "program" */ "@/views/ProgramView.vue"),
  },
  {
    path: "/brochure/:short_name/thankyou",
    name: "ProgramthankyouView",
    component: () =>
      import(
        /* webpackChunkName: "program-thankyou" */ "@/views/ProgramThankyou.vue"
      ),
  },
  {
    path: "/technology",
    name: "TechnologyView",
    component: () =>
      import(/* webpackChunkName: "technology" */ "@/views/TechnologyView.vue"),
  },
  {
    path: "/lp/online-mba-degree-program/",
    redirect: "/lp/online-mba-degree/",
  },
  {
    path: "/lp/online-:afterOnline/",
    name: "OnlineMbaFinance",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "lp-mba" */ "@/views/LPDesign/OnlineMbaFinance.vue"
      ),
  },
  {
    path: "/lp/online-:thankyouSlug/thankyou/",
    name: "OnlineMbaFinanceThankyou",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "lp-mba-thankyou" */ "@/views/LPDesign/OnlineMbaFinanceThankyou.vue"
      ),
  },
  {
    path: "/lp/online-degree-programs/",
    name: "OnlineDegree",
    component: () =>
      import(
        /* webpackChunkName: "lp-degree-programs" */ "@/views/LPDesign/OnlineDegree.vue"
      ),
  },
  {
    path: "/lp/online-mba-degree/",
    name: "OnlineMba",
    component: () =>
      import(
        /* webpackChunkName: "lp-mba-degree" */ "@/views/LPDesign/OnlineMba.vue"
      ),
  },
  {
    path: "/thankyou/",
    name: "ThankYou",
    component: () =>
      import(
        /* webpackChunkName: "AllProgram" */ "@/views/LPDesign/ThankYou.vue"
      ),
  },
  {
    path: "/life-at-jo/",
    name: "LifeAt",
    component: () =>
      import(/* webpackChunkName: "life-at-jo" */ "@/views/LifeAtJoView.vue"),
  },
  {
    path: "/careers/",
    name: "CareerView",
    component: () =>
      import(/* webpackChunkName: "careers" */ "@/views/CareerView.vue"),
  },

  {
    path: "/about-us/",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/AboutUsView.vue"),
  },
  {
    path: "/lp/:slug/",
    name: "OnlineView",
    props: true,
    component: () =>
      import(/* webpackChunkName: "lp" */ "@/views/LPDesign/OnlineView.vue"),
  },
  {
    path: "/lp/:slug/thankyou/",
    name: "OnlineViewThankyou",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "lp-thankyou" */ "@/views/LPDesign/OnlineViewThankyou.vue"
      ),
  },
  // {
  //   path: "/lp/online/thankyou/",
  //   name: "LpOnlineThankyou",
  //   props: true,
  //   component:LpOnlineThankyou,

  // },
  {
    path: "/lp/online/p2/",
    name: "Onlinep2",
    component: () =>
      import(/* webpackChunkName: "lp-2" */ "@/views/LPDesign/OnlineP2.vue"),
  },
  {
    path: "/lp/online/p2/thankyou/",
    name: "Onlinep2Thankyou",
    component: () =>
      import(
        /* webpackChunkName: "lp-2-thankyou" */ "@/views/LPDesign/OnlineP2Thankyou.vue"
      ),
  },
  {
    path: "/lp/online/p5/",
    name: "Onlinep5",
    component: () =>
      import(
        /* webpackChunkName: "lp-5-thankyou" */ "@/views/LPDesign/OnlineP5.vue"
      ),
  },
  {
    path: "/lp/online/p5/thankyou/",
    name: "Onlinep5Thankyou",
    component: () =>
      import(
        /* webpackChunkName: "lp-5-thankyou" */ "@/views/LPDesign/OnlineP5Thankyou.vue"
      ),
  },
  {
    path: "/lp/online-degree-program/",
    name: "OnlineProgram",
    component: () =>
      import(
        /* webpackChunkName: "lp-degree-program" */ "@/views/LPDesign/OnlineProgram.vue"
      ),
  },
  {
    path: "/lp/online-degree-program/thankyou/",
    name: "OnlineProgramThankyou",
    component: () =>
      import(
        /* webpackChunkName: "lp-degree-program-thankyou" */ "@/views/LPDesign/OnlineProgramThankyou.vue"
      ),
  },
  {
    path: "/outreach/",
    name: "Outreach",
    component: () =>
      import(
        /* webpackChunkName: "outreach" */ "@/views/LPDesign/OutreachView.vue"
      ),
  },
  {
    path: "/lp/online-degree-courses/thankyou/",
    name: "OnlineDegreeThank",
    component: () =>
      import(
        /* webpackChunkName: "degree-course-thankyou" */ "@/views/LPDesign/OnlineDegreeThank.vue"
      ),
  },
  {
    path: "/lp/online-mba-degree/thankyou/",
    name: "OnlineMbaThank",
    component: () =>
      import(
        /* webpackChunkName: "lp-mba-thankyou" */ "@/views/LPDesign/OnlineMbaThank.vue"
      ),
  },
  {
    path: "/lp/corporate/",
    name: "CorporateLp",
    component: () =>
      import(
        /* webpackChunkName: "lp-mba-thankyou" */ "@/views/LPDesign/CorporateView.vue"
      ),
  },
  {
    path: "/lp/corporate/thankyou",
    name: "CorporateLpthankyou",
    component: () =>
      import(
        /* webpackChunkName: "lp-mba-thankyou" */ "@/views/LPDesign/CorporatethankyouView .vue"
      ),
  },

  {
    path: "/search/",
    name: "SearchView",
    component: () =>
      import(/* webpackChunkName: "AllProgram" */ "@/views/SearchView.vue"),
  },
  {
    path: "/lp/online-degree",
    name: "LpnewView",
    component: () =>
      import(/* webpackChunkName: "AllProgram" */ "@/views/LpnewView.vue"),
  },
  {
    path: "/lp/online-degree/thank-you",
    name: "lpThankyou",
    component: () =>
      import(
        /* webpackChunkName: "AllProgram" */ "@/views/LpnewThankyouView.vue"
      ),
  },
  {
    path: "/lp/online-mca",
    name: "McalpView",
    component: () =>
      import(
        /* webpackChunkName: "McalpView" */ "@/views/UniproLp/McaView.vue"
      ),
  },
  {
    path: "/lp/online-mca/thank-you",
    name: "McalpthankyouView",
    component: () =>
      import(
        /* webpackChunkName: "McalpthankyouView" */ "@/views/UniproLp/McathankyouView.vue"
      ),
  },
  {
    path: "/lp/online-mcom",
    name: "McomlpView",
    component: () =>
      import(
        /* webpackChunkName: "McomlpView" */ "@/views/UniproLp/McomView.vue"
      ),
  },
  {
    path: "/lp/online-mcom/thank-you",
    name: "McomlpthankyouView",
    component: () =>
      import(
        /* webpackChunkName: "McomlpthankyouView" */ "@/views/UniproLp/McomthankyouView.vue"
      ),
  },
  {
    path: "/lp/online-ma",
    name: "MalpView",
    component: () =>
      import(/* webpackChunkName: "MalpView" */ "@/views/UniproLp/MaView.vue"),
  },
  {
    path: "/lp/online-ma/thank-you",
    name: "MalpthankyouView",
    component: () =>
      import(
        /* webpackChunkName: "MalpthankyouView" */ "@/views/UniproLp/MathankyouView.vue"
      ),
  },
  {
    path: "/lp/online-bba",
    name: "BbalpView",
    component: () =>
      import(
        /* webpackChunkName: "BbalpView" */ "@/views/UniproLp/BbaView.vue"
      ),
  },
  {
    path: "/lp/online-bba/thank-you",
    name: "BbalpthankyouView",
    component: () =>
      import(
        /* webpackChunkName: "BbalpthankyouView" */ "@/views/UniproLp/BbathankyouView.vue"
      ),
  },
  {
    path: "/lp/online-bcom",
    name: "BcomlpView",
    component: () =>
      import(
        /* webpackChunkName: "BcomlpView" */ "@/views/UniproLp/BcomView.vue"
      ),
  },
  {
    path: "/lp/online-bcom/thank-you",
    name: "BcomlpthankyouView",
    component: () =>
      import(
        /* webpackChunkName: "BcomlpthankyouView" */ "@/views/UniproLp/BcomthankyouView.vue"
      ),
  },
  {
    path: "/lp/alumni",
    name: "lpAlumni",
    component: () =>
      import(
        /* webpackChunkName: "AllProgram" */ "@/views/LPDesign/AluminaiLp.vue"
      ),
  },
  {
    path: "/lp/alumni/thank-you",
    name: "AlumniThankyou",
    component: () =>
      import(
        /* webpackChunkName: "AllProgram" */ "@/views/LPDesign/AluminaiLpThankyou.vue"
      ),
  },
  {
    path: "/lp/online-mba",
    name: "lpOnlineMba",
    component: () =>
      import(
        /* webpackChunkName: "lpPages" */ "@/views/LPDesign/onlineLpMba.vue"
      ),
  },
  {
    path: "/lp/online-mba/thank-you",
    name: "lpOnlineMbaThankyou",
    component: () =>
      import(
        /* webpackChunkName: "lpPages" */ "@/views/LPDesign/onlineLpMbaThankyou.vue"
      ),
  },
  {
    path: "/lp/online-mbadegree",
    name: "MbadegreeView",
    component: () =>
      import(
        /* webpackChunkName: "MbadegreeView" */ "@/views/UniproLp/MbadegreeView.vue"
      ),
  },
  {
    path: "/lp/online-mbadegree/thank-you",
    name: "MbadegreethankyouView",
    component: () =>
      import(
        /* webpackChunkName: "MbadegreethankyouView" */ "@/views/UniproLp/MbadegreethkuView.vue"
      ),
  },
  {
    path: "/lp/testing",
    name: "DummyLpView",
    component: () =>
      import(/* webpackChunkName: "AllProgram" */ "@/views/DummyLp.vue"),
  },
  {
    path: "/download-whitepaper-document-thank-you/",
    name: "WhitepaperThakyou",
    component: () =>
      import(
        /* webpackChunkName: "WhitepaperThakyou" */ "@/views/MediaThankyou/WhitepaperViewThankyou.vue"
      ),
  },
  {
    path: "/download-ebook-thank-you/",
    name: "EbbokThakyou",
    component: () =>
      import(
        /* webpackChunkName: "EbbokThakyou" */ "@/views/MediaThankyou/EbbokViewThankyou.vue"
      ),
  },
  {
    path: "/lp/online-degree-india",
    name: "UnilpView",
    component: () =>
      import(
        /* webpackChunkName: "UnilpView" */ "@/views/LPDesign/getmyuni/LpnewView.vue"
      ),
  },
  {
    path: "/lp/online-degree-india/thank-you",
    name: "UniThankyou",
    component: () =>
      import(
        /* webpackChunkName: "UniThankyou" */ "@/views/LPDesign/getmyuni/LpnewThankyouView.vue"
      ),
  },
  {
    path: "/lp/online-bba/display",
    name: "UniproDisplaybbaView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplaybbaView" */ "@/views/UniproLp/display/BbaDisplay.vue"
      ),
  },
  {
    path: "/lp/online-bba/display/thank-you",
    name: "UniproDisplaybbathkView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplaybbathkView" */ "@/views/UniproLp/display/BbaDispalythk.vue"
      ),
  },
  {
    path: "/lp/online-mba/display",
    name: "OnlineLpMbaDisplay",
    component: () =>
      import(
        /* webpackChunkName: "OnlineLpMbaDisplay" */ "@/views/UniproLp/display/MbaDisplay.vue"
      ),
  },
  {
    path: "/lp/online-mba/display/thank-you",
    name: "OnlineLpMbaDisplaythankyou",
    component: () =>
      import(
        /* webpackChunkName: "OnlineLpMbaDisplaythankyou" */ "@/views/UniproLp/display/MbaDisplaythk.vue"
      ),
  },
  {
    path: "/lp/online-mca/display",
    name: "UniproDisplaymcaView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplaymcaView" */ "@/views/UniproLp/display/McaDisplay.vue"
      ),
  },
  {
    path: "/lp/online-mca/display/thank-you",
    name: "UniproDisplaymcaathkView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplaymcaathkView" */ "@/views/UniproLp/display/McaDispalythk.vue"
      ),
  },
  {
    path: "/lp/online-ma/display",
    name: "UniproDisplaymaView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplaymaView" */ "@/views/UniproLp/display/MaDisplay.vue"
      ),
  },
  {
    path: "/lp/online-ma/display/thank-you",
    name: "UniproDisplaymathkView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplaymathkView" */ "@/views/UniproLp/display/MaDispalythk.vue"
      ),
  },
  {
    path: "/lp/online-mcom/display",
    name: "UniproDisplaymcomView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplaymcomView" */ "@/views/UniproLp/display/McomDisplay.vue"
      ),
  },
  {
    path: "/lp/online-mcom/display/thank-you",
    name: "UniproDisplaymcomthkView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplaymcomthkView" */ "@/views/UniproLp/display/McomDispalythk.vue"
      ),
  },
  {
    path: "/lp/online-bcom/display",
    name: "UniproDisplaybcomView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplaybcomView" */ "@/views/UniproLp/display/BcomDisplay.vue"
      ),
  },
  {
    path: "/lp/online-bcom/display/thank-you",
    name: "UniproDisplaybcomthkView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplaybcomthkView" */ "@/views/UniproLp/display/BcomDispalythk.vue"
      ),
  },
  {
    path: "/lp/online-degree/display",
    name: "UniproDisplayonlineView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplayonlineView" */ "@/views/UniproLp/display/OnlineDisplay.vue"
      ),
  },
  {
    path: "/lp/online-degree/display/thank-you",
    name: "UniproDisplayonlinethkView",
    component: () =>
      import(
        /* webpackChunkName: "UniproDisplayonlinethkView" */ "@/views/UniproLp/display/OnlineDispalythk.vue"
      ),
  },
  {
    path: "/lp/online-mba/meta",
    name: "MbaMetaView",
    component: () =>
      import(
        /* webpackChunkName: "MbaMetaView" */ "@/views/UniproLp/uniprometa/MbaMeta.vue"
      ),
  },
  {
    path: "/lp/online-degree/meta",
    name: "OnlineMetaView",
    component: () =>
      import(
        /* webpackChunkName: "OnlineMetaView" */ "@/views/UniproLp/uniprometa/OnlineMeta.vue"
      ),
  },
  {
    path: "/lp/best-online-degree-programs",
    name: "UniBestView",
    component: () =>
      import(
        /* webpackChunkName: "UniBestView" */ "@/views/LPDesign/getmyuni/LpBestView.vue"
      ),
  },
  {
    path: "/lp/best-online-degree-programs/thank-you",
    name: "UniBestThankyou",
    component: () =>
      import(
        /* webpackChunkName: "UniBestThankyou" */ "@/views/LPDesign/getmyuni/LpBestThankyouView.vue"
      ),
  },
  {
    path: "/lp/india-online-degree-programs",
    name: "WalkingView",
    component: () =>
      import(
        /* webpackChunkName: "WalkingView" */ "@/views/LPDesign/walking/LpwalkingView.vue"
      ),
  },
  {
    path: "/lp/india-online-degree-programs/thank-you",
    name: "WalkingThankyou",
    component: () =>
      import(
        /* webpackChunkName: "WalkingThankyou" */ "@/views/LPDesign/walking/LpwalkingThankyouView.vue"
      ),
  },
  {
    path: "/micro-credentials-online-courses",
    name: "MicroCredentialView",
    component: () =>
      import(
        /*webpackChunkName: "MicroCredentialView" */ "@/views/MicroView.vue"
      ),
  },
  {
    path: "/ai-certifications",
    name: "AI-Certification",
    component: () =>
      import(
        /* webpackChunkName: "AI-Certification" */ "@/views/CertificationView.vue"
      ),
    props: true,
  },
  // redirection
  {
    path: "/programs/pgcp?page=1",
    redirect: "/all-programs",
  },
  {
    path: "/programs/certifications?page=1",
    redirect: "/all-programs",
  },
  {
    path: "/sites/default/files/2021-12/International Marketing.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Marketing.pdf",
  },
  {
    path: "/programs/masters?page=1",
    redirect: "/all-programs",
  },
  {
    path: "/sites/default/files/2021-12/Marketing and Human Resource Management.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Human-Resource-Management.pdf",
  },
  {
    path: "/sites/default/files/2021-12/General Management .pdf",
    redirect: "/sites/default/files/2022-11/MBA-General-Management.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Artificial Intelligence.pdf",
    redirect: "/sites/default/files/2022-11/MCA- Artificial-Intelligence.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Entrepreneurship and Leadership.pdf",
    redirect: "/pdf/MBA-Entrepreneurship-and-Leadership.pdf",
  },
  {
    path: "/sites/default/files/2021-12/International Marketing .pdf",
    redirect: "/master-business-administration-online-degree",
  },
  {
    path: "/sites/default/files/2021-12/Strategic Finance .pdf",
    redirect: "/sites/default/files/2022-11/MBA-Strategic-Finance-(Accredited-by-CPA-US+CMA-US ).pdf",
  },
  {
    path: "/sites/default/files/2021-12/Information Technology Management.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Information-Technology-Management.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Logistics _ Supply Chain Management.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Logistics-and-Supply-Chain-Management.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Professional Accounting _ Finance.pdf",
    redirect: "/sites/default/files/2022-11/M.Com-Professional-Accounting-and-Finance-(Accredited-by-CPA-US)_compressed.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Human Resource Management_0.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Human-Resource-Management.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Healthcare Management .pdf",
    redirect: "/sites/default/files/2022-11/MBA-Healthcare-Management.pdf",
  },
  {
    path: "/sites/default/files/2022-02/mba_project_management.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Project-Management.pdf",
  },
  {
    path: "/sites/default/files/2022-02/bba_engineering_and_project_management.pdf",
    redirect: "/pdf/BBA-Engineering-and-Project-Management.pdf",
  },
  {
    path: "/sites/default/files/2022-02/mcom_international_finance.pdf",
    redirect: "/sites/default/files/2022-11/MBA-International-Finance-(Accredited-by-ACCA-UK).pdf",
  },
  {
    path: "/programs/masters/mba-advertising-and-branding-degree-online",
    redirect: "/master-business-administration-online-degree",
  },
  {
    path: "/programs/certifications?q=jain/programs/certifications&page=1",
    redirect: "/micro-credentials-online-courses",
  },
  {
    path: "/sites/default/files/2021-12/Project Management.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Project-Management.pdf",
  },
  {
    path: "/sites/default/files/2021-12/International Finance.pdf",
    redirect: "/sites/default/files/2022-11/MBA-International-Finance-(Accredited-by-ACCA-UK).pdf",
  },
  {
    path: "/sites/default/files/2021-12/Human Resource Management and Finance.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Human-Resource-Management-and-Finance.pdf",
  },
  {
    path: "/sites/default/files/2022-03/MBA-Human Resource Management_0.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Human-Resource-Management.pdf",
  },
  {
    path: "/programs/bachelors?page=2",
    redirect: "/all-programs",
  },
  {
    path: "/index.php/programs/masters?page=3",
    redirect: "/all-programs",
  },
  {
    path: "/sites/default/files/program_brochure/Program-Brochure-MBA_0.pdf",
    redirect: "/pdf/Program-Brochure-MBA.pdf",
  },
  {
    path: "/JIVA.pdf",
    redirect: "/",
  },
  {
    path: "/sites/default/files/program_brochure/ma_program_brochure.pdf",
    redirect: "/pdf/Program-Brochure-MA.pdf",
  },
  {
    path: "/sites/default/files/2022-01/bba_finance.pdf",
    redirect: "/sites/default/files/2022-11/BBA-Finance.pdf",
  },
  {
    path: "/sites/default/files/2022-02/mba_marketing_and_human_resource_management.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Marketing-and-Human-Resource-Management.pdf",
  },
  {
    path: "/sites/default/files/program_brochure/Program-Brochure-MA_0.pdf",
    redirect: "/pdf/Program-Brochure-MA.pdf",
  },
  {
    path: "/sites/default/files/2023-10/Calendar-of-Events-All-Batches.pdf",
    redirect: "/",
  },
  {
    path: "/sites/default/files/2023-10/Calender-of-Events-All-Batches.pdf",
    redirect: "/",
  },
  {
    path: "/sites/default/files/2023-10/JAIN-Online-TnC-docx.pdf",
    redirect: "https://api.onlinejain.com/media/JAIN-Online-TnC-docx.pdf",
  },
  {
    path: "/sites/default/files/2023-11/Calendar-of-Events-Post-Graduate-Programs.pdf",
    redirect: "https://api.onlinejain.com/media/alumni/Online_PG_Calendar_Jan_2024_SEM_I_26.12_Students-(4)_85459.pdf",
  },
  {
    path: "/sites/default/files/2023-11/Calendar-of-Events-Under-Graduate-Programs.pdf",
    redirect: "https://api.onlinejain.com/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  },
  {
    path: "/sites/default/files/2022-02/mba_healthcare_management.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Healthcare-Management.pdf",
  },
  {
    path: "/sites/default/files/2023-12/Calendar-of-Events-Post-Graduate-Programs.pdf",
    redirect: "https://api.onlinejain.com/media/alumni/Online_PG_Calendar_Jan_2024_SEM_I_26.12_Students-(4)_85459.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Data Science.pdf",
    redirect: "/sites/default/files/2022-11/MCA-Data-Science.pdf",
  },
  {
    path: "/sites/default/files/2024-01/22-Jan- Calendar-of-Events-Under-Graduate-Programs.pdf",
    redirect: "https://api.onlinejain.com/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Finance_0.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Finance.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Banking and Finance.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Banking-and-Finance.pdf",
  },
  {
    path: "/sites/default/files/program_brochure/Program-Brochure-MCA.pdf",
    redirect: "/pdf/Program-Brochure-MCA.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Marketing_0.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Marketing.pdf",
  },
  {
    path: "/sites/default/files/2024-01/22-Jan-Calendar-of-Events-Post-Graduate-Programs.pdf",
    redirect: "https://api.onlinejain.com/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  },
  {
    path: "/sites/default/files/2024-01/Calendar-of-Events-Post-Graduate-Programs.pdf",
    redirect: "https://api.onlinejain.com/media/alumni/Online_PG_Calendar_Jan_2024_SEM_I_26.12_Students-(4)_85459.pdf",
  },
  {
    path: "/sites/default/files/2022-02/mba_international_finance.pdf",
    redirect: "/sites/default/files/2022-11/MBA-International-Finance-(Accredited-by-ACCA-UK).pdf",
  },
  {
    path: "/sites/default/files/2021-12/Data Analytics.pdf",
    redirect: "/pdf/MCA-Data-Analytics-(Accredited-by-IoA,UK).pdf",
  },
  {
    path: "/sites/default/files/2024-01/Calendar-of-Events-Under-Graduate-Programs.pdf",
    redirect: "https://api.onlinejain.com/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Cloud Computing.pdf",
    redirect: "/sites/default/files/2022-11/MCA-Cloud-Computing.pdf",
  },
  {
    path: "/sites/default/files/2024-02/Calendar-of-Events-Post-Graduate-2024.pdf",
    redirect: "https://api.onlinejain.com/media/alumni/Online_PG_Calendar_Jan_2024_SEM_I_26.12_Students-(4)_85459.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Computer Science _ IT.pdf",
    redirect: "/sites/default/files/2022-11/MCA-Computer-Science-and-IT.pdf",
  },
  {
    path: "/sites/default/files/2024-02/Calendar-of-Events-Under-Graduate-2024.pdf",
    redirect: "https://api.onlinejain.com/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  },
  {
    path: "/sites/default/files/2024-03/Calendar-of-Events-Post-Graduate-2024_0.pdf",
    redirect: "https://api.onlinejain.com/media/alumni/Online_PG_Calendar_Jan_2024_SEM_I_26.12_Students-(4)_85459.pdf",
  },
  {
    path: "/sites/default/files/2024-03/Calendar-of-Events-Under-Graduate-2024.pdf",
    redirect: "https://api.onlinejain.com/media/alumni/Online_UG_Calendar_Jul_2021_SEM_VI_01.03_Students_12591.pdf",
  },
  {
    path: "/sites/default/files/2022-02/mca_cloud_computing.pdf",
    redirect: "/sites/default/files/2022-11/MCA-Cloud-Computing.pdf",
  },
  {
    path: "/sites/default/files/program_brochure/Program-Brochure-BBA.pdf",
    redirect: "/pdf/Program-Brochure-BBA.pdf",
  },
  {
    path: "/sites/default/files/program_brochure/Program-Brochure-MA.pdf",
    redirect: "/pdf/Program-Brochure-MA.pdf",
  },
  {
    path: "/sites/default/files/2022-02/mba_banking_finance.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Banking-and-Finance.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Marketing.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Marketing.pdf",
  },
  {
    path: "/sites/default/files/2021-12/Banking _ Finance.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Banking-and-Finance.pdf",
  },
  {
    path: "/blogs/other?page=1",
    redirect: "/blogs",
  },
  {
    path: "/sites/default/files/media_images/media_kit_brand_guidelines.pdf",
    redirect: "/",
  },
  {
    path: "/sites/default/files/2021-12/Accounting and Finance.pdf",
    redirect: "/sites/default/files/2022-11/M.Com-Accounting-and-Finance_compressed.pdf",
  },
  {
    path: "/sites/default/files/2022-02/mba_marketing.pdf",
    redirect: "/sites/default/files/2022-11/MBA-Marketing.pdf",
  },
  {
    path: "/programs/masters/mba-advertising-and-branding-degree-online",
    redirect: "/master-business-administration-online-degree",
  },
  {
    path: "/https://onlinejain.com/;",
    redirect: "https://onlinejain.com",
  },
  {
    path: "/certifications@onlinejain.com",
    redirect: "https://onlinejain.com",
  },
  {
    path: "/faqs/scholarships",
    redirect: "/faqs",
  },
  {
    path: "/online-mba/strategic-finance",
    redirect: "/master-business-administration-online-degree",
  },
  {
    path: "/online-bcom/corporate-accounting",
    redirect: "/bachelor-of-commerce-online-degree",
  },
  {
    path: "/master-of-arts-online-degree",
    redirect: "/",
  },
  {
    path: "/online-ma/english",
    redirect: "/",
  },
  {
    path: "/online-ma/economics",
    redirect: "/",
  },
  {
    path: "/online-ma/public-policy",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 10, behavior: "smooth" };
  },
});
window.isProgrammaticNavigation = false;
router.beforeEach((to, from, next) => {
  if (to.path === "/search") {
    if (window.isProgrammaticNavigation) {
      window.isProgrammaticNavigation = false;
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  const redirectPath = getRedirectPath(to.path);
  
  if (redirectPath) {
    next({ path: redirectPath });
  } else {
    next();
  }
});
export default router;